import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const SavedView = Loadable(() => import("./SavedView"));

function SavedViewAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const SavedViewContainer = (props) => {
  return <SavedView {...props} fallback={<SavedViewAsyncLoading />} />;
};

export default SavedViewContainer;
