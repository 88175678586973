import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

export const loadingStyles = {
  height: {
    height: "231.25rem",
    maxHeight: "231.25rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "350rem",
      maxHeight: "350rem",
    },
  },
};

const HomepageView = Loadable(() =>
  import(/* webpackChunkName: "HomepageView" */ "./HomepageView")
);

function HomepageViewAsyncLoading(props) {
  return <LoadingOverlay height={"231.25rem"} styles={loadingStyles} />;
}

const HomepageViewContainer = (props) => {
  return <HomepageView {...props} fallback={<HomepageViewAsyncLoading />} />;
};

export default HomepageViewContainer;
