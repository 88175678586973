import axios from "axios";

import configGet, { configHas } from "../../../config";

import { getToken, setToken, tokenExpired } from "utils/auth";

try {
  axios.defaults.baseURL = configHas("api.serverBaseUrl")
    ? configGet("api.serverBaseUrl")
    : configGet("api.clientBaseUrl");
} catch (e) {
  // likely running via storybook
  console.warn(e);
}

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const token = getToken();
    if (!token) {
      return config;
    }
    const expired = tokenExpired(token);

    if (expired && !config.url.match(/auth\/refresh\/?$/)) {
      return axios
        .get("/auth/refresh")
        .then((response) => {
          const { token } = response.data;

          config.headers.common.Authorization = `Bearer ${token}`;
          setToken(token);
        })
        .then(() => config)
        .catch(() => config);
    }

    return config;
  },
  (error) => Promise.reject(error)
);

let instance;

if (typeof window === "undefined") {
  // we're on the server, so pass a custom http and https agent with keepalive turned on
  const http = require("http");
  const https = require("https");

  const httpAgent = new http.Agent({ keepAlive: true });
  const httpsAgent = new https.Agent({ keepAlive: true });

  instance = axios.create({
    httpAgent,
    httpsAgent,
  });
} else {
  instance = axios.create();
}

export default instance;
