import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

export const loadingStyles = {
  height: {
    height: "293rem",
    maxHeight: "293rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "136rem",
      maxHeight: "136rem",
    },
  },
};

const PodcastViewContainer = Loadable(() =>
  import(
    /* webpackChunkName: "PodcastViewContainer" */ "./PodcastViewContainer"
  )
);

function PodcastViewAsyncLoading() {
  return <LoadingOverlay height={"293rem"} styles={loadingStyles} />;
}

const PodcastViewContainerContainer = (props) => {
  return (
    <PodcastViewContainer {...props} fallback={<PodcastViewAsyncLoading />} />
  );
};

export default PodcastViewContainerContainer;
