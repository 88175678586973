import { commonModuleGroup, podcastModuleGroup } from "modules/groups";
import {
  CATEGORY_MODULE,
  CLAIMING_MODULE,
  CREATOR_VIEW_MODULE,
  EXPORTS_MODULE,
  PAGINATION_MODULE,
  SIMPLE_FORMS_MODULE,
  TAG_MODULE,
  USERLIST_MODULE,
  USERLIST_COMMON_MODULE,
  BADGES_MODULE,
  ADVERTISE_MODULE,
  INSIGHTS_PDF_MODULE,
} from "modules/moduleIds";
import { Redirect, Switch } from "react-router-dom";

import RegisteredRoute from "components/Common/RegisteredRoute";
import AccountManagementContainer from "pages/Account/Settings/async";
import RegistrationRequired from "pages/Auth/RegistrationRequired";
import TopLevelRoute from "pages/Common/TopLevelRoute";
import BadgesView from "pages/Profile/Badges/async";
import ProfileBookmarks from "pages/Profile/Bookmarks/async";
import ProfileSearches from "pages/Profile/Searches/async";

import ClaimedPodcasts from "./Claim/ClaimedPodcasts/async";
import ManagePodcast from "./Claim/ManagePodcast/async";
import AlertsPage from "./Profile/Alerts/async";
import ManageCreators from "./Profile/Creators/async";
import ProfileFollowedView from "./Profile/Followed/async";
import ProfileLikedView from "./Profile/Liked/async";
import ProfileProViewAsync from "./Profile/Pro/async";
import RatedView from "./Profile/Rated/async";
import ProfileReviewedView from "./Profile/Reviewed/async";
import SavedView from "./Profile/Saved/async";
import ProfileUserLists from "./Profile/UserLists/async";
import WatchlistView from "./Profile/Watchlist/async";
import UserListEdit from "./UserListEdit/async";

function ProfileRoutes({ isDesktop, topLevelRouteProps }) {
  return (
    <Switch>
      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/profile/list(s?)/:id/edit"
        notRegisteredComponent={RegistrationRequired}
        transparent={false}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          USERLIST_MODULE,
          CREATOR_VIEW_MODULE,
          CLAIMING_MODULE,
        ]}
        {...topLevelRouteProps}
      >
        <UserListEdit />
      </TopLevelRoute>

      {/* Profile */}
      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/profile/saved/:type?"
        notRegisteredComponent={RegistrationRequired}
        transparent={false}
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          USERLIST_COMMON_MODULE,
          CLAIMING_MODULE,
        ]}
      >
        <SavedView />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/profile/rated/:type?"
        notRegisteredComponent={RegistrationRequired}
        transparent={false}
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          USERLIST_COMMON_MODULE,
          CLAIMING_MODULE,
        ]}
      >
        <RatedView />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/profile/liked/:type?"
        notRegisteredComponent={RegistrationRequired}
        transparent={false}
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          USERLIST_COMMON_MODULE,
          CLAIMING_MODULE,
        ]}
      >
        <ProfileLikedView />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/profile/reviewed/:subtype?"
        notRegisteredComponent={RegistrationRequired}
        transparent={false}
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          USERLIST_COMMON_MODULE,
          CLAIMING_MODULE,
        ]}
      >
        <ProfileReviewedView />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/profile/followed/:type?"
        notRegisteredComponent={RegistrationRequired}
        transparent={false}
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          USERLIST_COMMON_MODULE,
          CLAIMING_MODULE,
        ]}
      >
        <ProfileFollowedView />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/profile/claimed"
        exact
        notRegisteredComponent={RegistrationRequired}
        transparent={false}
        {...topLevelRouteProps}
        modules={[...podcastModuleGroup, CLAIMING_MODULE]}
      >
        <ClaimedPodcasts />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/profile/alerts"
        exact
        notRegisteredComponent={RegistrationRequired}
        transparent={false}
        {...topLevelRouteProps}
        modules={[...podcastModuleGroup, CLAIMING_MODULE]}
      >
        <AlertsPage />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/profile/claimed/:podcast_id"
        notRegisteredComponent={RegistrationRequired}
        transparent={false}
        {...topLevelRouteProps}
        modules={[
          ...podcastModuleGroup,
          CLAIMING_MODULE,
          SIMPLE_FORMS_MODULE,
          CATEGORY_MODULE,
          TAG_MODULE,
          ADVERTISE_MODULE,
        ]}
      >
        <ManagePodcast />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/profile/creators"
        notRegisteredComponent={RegistrationRequired}
        transparent={isDesktop}
        {...topLevelRouteProps}
        modules={[PAGINATION_MODULE, CREATOR_VIEW_MODULE, CLAIMING_MODULE]}
      >
        <ManageCreators />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/profile/credit_submissions"
        notRegisteredComponent={RegistrationRequired}
        transparent={isDesktop}
        {...topLevelRouteProps}
        modules={[PAGINATION_MODULE, CREATOR_VIEW_MODULE]}
      >
        <ManageCreators />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/profile/lists/:subtype?" // (|/podcasts|/episodes|/all|/private|/public|/link_only)
        notRegisteredComponent={RegistrationRequired}
        transparent={false}
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          USERLIST_COMMON_MODULE,
          CLAIMING_MODULE,
        ]}
      >
        <ProfileUserLists />
      </TopLevelRoute>

      <TopLevelRoute
        path="/profile/pro/exports"
        {...topLevelRouteProps}
        transparent={false}
        exact={true}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          EXPORTS_MODULE,
          INSIGHTS_PDF_MODULE,
          USERLIST_COMMON_MODULE,
        ]}
      >
        <ProfileProViewAsync />
      </TopLevelRoute>

      <TopLevelRoute
        path="/profile/settings"
        exact
        transparent={false}
        {...topLevelRouteProps}
      >
        <Redirect to="/profile/settings/account" />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/profile/settings"
        notRegisteredComponent={RegistrationRequired}
        transparent={false}
        {...topLevelRouteProps}
      >
        <AccountManagementContainer />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        notRegisteredComponent={RegistrationRequired}
        path="/profile/bookmarks/:type?"
        {...topLevelRouteProps}
        transparent={false}
        modules={[...commonModuleGroup, ...podcastModuleGroup, CLAIMING_MODULE]}
      >
        <ProfileBookmarks />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        notRegisteredComponent={RegistrationRequired}
        path="/profile/searches/:type?"
        {...topLevelRouteProps}
        transparent={false}
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <ProfileSearches />
      </TopLevelRoute>

      <TopLevelRoute
        path="/profile/brands/"
        {...topLevelRouteProps}
        transparent={false}
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <WatchlistView />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        notRegisteredComponent={RegistrationRequired}
        path="/profile/badges/"
        {...topLevelRouteProps}
        transparent={false}
        modules={[...commonModuleGroup, ...podcastModuleGroup, BADGES_MODULE]}
      >
        <BadgesView />
      </TopLevelRoute>
    </Switch>
  );
}

export default ProfileRoutes;
