import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

import PodchaserBrandLogo from "components/Branding/PodchaserBrandLogo";
import Col from "components/Common/Col";
import { useAbTestingContext } from "containers/AbTesting/AbTestingProvider";
import {
  AB_TESTING_EXPERIMENTS,
  AB_TESTING_VERSIONS,
} from "containers/AbTesting/AbTestingProvider";

import NavMenuItem from "../NavMenu/NavMenuItem";
import useNavLinks from "../NavMenu/useNavLinks";
import HeaderMenuToggle from "./HeaderMenuToggle";
import HeaderSearch from "./HeaderSearch";
import HeaderUser from "./HeaderUserAsync";

import { userHasPermission } from "utils/entity/user";
import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";
import sendGAEvent from "utils/sendGAEvent";

import useLoggedInUser, { useLoggedIn } from "hooks/useLoggedInUser";
import useMemo from "hooks/useLoggedMemo";
import { useScrollPosition } from "hooks/useScrollPosition";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import HeaderStyles from "styles/HeaderStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  ...HeaderStyles,
  extendedWidth: {
    maxWidth: "100%",
    padding: 0,
  },
  transparentContainer: {
    width: "100%",
    background: "none",
    boxShadow: "none",
    transition: "ease-out 0.2s",
    [createBreakpoint({ max: 1400 })]: {
      borderBottom: `1px solid rgba(255, 255, 255, 0.2)`,
    },
  },
  seperator: {
    border: "1px solid rgb(217, 217, 217, 0.21)",
    height: "2.25rem",
    borderRadius: "50%",
    display: "none",

    [createBreakpoint({ min: 1400 })]: {
      display: "block",
    },
  },
  mainHeaderContainer: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    gridGap: "0.625rem",
    padding: "0.875rem 1rem",
    alignItems: "center",
    height: "100%",
    color: colours.white,
    maxWidth: "130rem",

    [ScreenSizes.mdAndAbove]: {
      padding: "0.875rem 33px",
    },

    [createBreakpoint({ min: 1280 })]: {
      padding: "0.875rem 3rem",
    },

    [createBreakpoint({ min: 1400 })]: {
      gridTemplateColumns: "max-content 1fr max-content max-content",
    },
  },
  mainMenuContainer: {
    display: "grid",
    width: "100%",
    padding: "0 1rem",
    paddingRight: 0,
  },
  secondaryMenuContainer: {
    maxWidth: "100%",
    display: "grid",
    placeItems: "center",
    [createBreakpoint({ min: 1400 })]: {
      width: "100%",
      gridTemplateColumns: "max-content 1fr max-content",
    },
  },

  inner: {
    ...HeaderStyles.headerContent,
    maxWidth: "80rem",
    margin: "auto",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",

    [ScreenSizes.smOnly]: {
      ...HeaderStyles.headerContent[ScreenSizes.smOnly],
      paddingLeft: "0.25rem",
      paddingRight: "0.25rem",
    },
    [ScreenSizes.mdOnly]: {
      ...HeaderStyles.headerContent[ScreenSizes.mdOnly],
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    [ScreenSizes.mdAndBelow]: {
      ...HeaderStyles.headerContent[ScreenSizes.mdAndBelow],
      flexDirection: "column",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
  },
  section: {
    margin: "0 0.5rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [ScreenSizes.smAndAbove]: {
      margin: "0 1rem",
    },
    [ScreenSizes.mdAndAbove]: {
      margin: "0 2rem",
    },
    [ScreenSizes.lgAndAbove]: {
      margin: 0,
      justifyContent: "flex-start",
      display: "none",
    },
    [createBreakpoint({ min: 1400 })]: {
      display: "flex",
    },
  },
  sectionLeft: {
    [ScreenSizes.mdAndBelow]: {
      display: "none",
    },
  },
  sectionRight: {
    [ScreenSizes.mdAndBelow]: {
      display: "none",
    },
  },
  whiteContainer: {
    background: colours.white,
    transition: "ease-out 0.2s",
    borderBottom: `1px solid ${colours.lightGreyBorder}`,
    boxShadow: "none",

    [createBreakpoint({ min: 1400 })]: {
      boxShadow: "1px 6px 5px 0px rgba(0, 0, 0, 0.12)",
      borderBottom: "none",
    },
  },
  headerPlaceholder: {
    width: "100%",
  },
};

const headerSearchOverride = {
  headerSearch: {
    width: "100%",
  },
};

const navItemWhiteColor = {
  navTitle: {
    color: colours.black,
  },
  navItem: {
    ":hover": {
      textShadow: "none",
    },
  },
};

const overrideStyles = {
  navItem: {
    ":hover": {
      textShadow: "none",
    },
  },
  navItemOuter: {
    ":nth-child(1)": {
      marginRight: "-0.25rem",
      marginLeft: "0.438rem",
    },
    ":last-child": {
      marginRight: "-0.4rem",
    },
  },
};

const leftStyles = {
  navItemOuter: {
    ":nth-child(1)": {
      marginRight: "0.75rem",
    },
  },
  navItem: {
    fontSize: "0.875rem",
    [ScreenSizes.mdAndAbove]: {
      fontSize: "1rem",
    },
    ":hover": {
      textShadow: "none",
    },
    ":last-child": {
      marginRight: "2.01rem",
    },
  },
  navItemActive: {
    fontSize: "0.875rem",
    [ScreenSizes.mdAndAbove]: {
      fontSize: "1rem",
    },
  },
};

const HeaderStandard = (props) => {
  const {
    transparent,
    headerContentContainerBackgroundColor,
    searchBarBackgroundColor,
    onMenuToggleClick,
    showUserContainer,
    hideSearchToggle,
    loggedIn,
    isHomepage,
  } = props;

  const { isWindowSizeOrMore } = useWindowSize();
  const isLargeOrMore = isWindowSizeOrMore("large");

  const { styles } = useStyles(baseStyles, props);

  const { isNewHomePageSearch } = useAbTestingContext();

  const isLoggedIn = useLoggedIn();

  const [showSearch, setShowSearch] = useState(isLargeOrMore);
  const [searchToggled, setSearchToggled] = useState(false);
  const { leftItems, rightItems } = useNavLinks();
  const user = useLoggedInUser();
  const hasPro = useUserHasPro();

  const scrollPosition = useScrollPosition(transparent, 50);

  const switchToWhiteStyles = useMemo(() => {
    if (transparent && scrollPosition > 18) {
      return true;
    }
    return false;
  }, [scrollPosition, transparent]);

  useEffect(() => {
    if (isLargeOrMore || !searchToggled) {
      setShowSearch(isLargeOrMore);
    }
  }, [isLargeOrMore, searchToggled]);

  // TODO: Give focus to search box
  const onSearchToggleClick = useCallback(
    () => setShowSearch((prevShowSearch) => !prevShowSearch),
    []
  );

  const onSearchCloseClick = useCallback(() => {
    if (!isLargeOrMore) {
      setShowSearch(false);
    }
  }, [isLargeOrMore]);

  const handleSearchChange = useCallback(() => setSearchToggled(true), []);
  const handleCancelSearch = useCallback(() => setSearchToggled(false), []);

  const handleSearchToggleKeyDown = useCallback(
    (e) => eventIsFieldTrigger(e) && onSearchToggleClick(e),
    [onSearchToggleClick]
  );

  const renderUser = () => (
    <HeaderUser
      onSearchToggleClick={onSearchToggleClick}
      onSearchToggleKeyDown={handleSearchToggleKeyDown}
      hideSearchToggle={hideSearchToggle}
      switchToWhiteStyles={switchToWhiteStyles}
    />
  );

  const logoProps = useMemo(
    () => ({
      iconOnly: !isLargeOrMore,
      textSize: "medium",
      ...(!isLargeOrMore && {
        width: "3rem",
        height: "3rem",
      }),
      dark: switchToWhiteStyles,
    }),
    [isLargeOrMore, switchToWhiteStyles]
  );

  const additionalHeaderProps = {
    headerLogo: true,
    loggedInProUser: loggedIn && hasPro,
  };

  const renderLogo = () => (
    <Col className={css(styles.headerLogoContainer)}>
      <div className={css(styles.headerLogoLinkContainer)}>
        <PodchaserBrandLogo
          {...logoProps}
          {...additionalHeaderProps}
          key={`PodchaserBrandLogo-${user?.get("id")}`}
        />
      </div>
    </Col>
  );

  const handleFocus = useCallback(() => {
    sendGAEvent({
      action: "abTestingBehaviour",
      context: "HomePage",
      abTestingName: AB_TESTING_EXPERIMENTS.HOMEPAGE_SEARCH,
      version: AB_TESTING_VERSIONS.CONTROL,
    });
  }, []);

  const renderSearch = () => {
    if (isNewHomePageSearch && !isLoggedIn && isHomepage) {
      return <div className={css(styles.headerPlaceholder)} />;
    }

    return (
      <HeaderSearch
        showSearch={showSearch}
        setShowSearch={setShowSearch}
        onSearchChange={handleSearchChange}
        onCancelSearch={handleCancelSearch}
        onSearchCloseClick={onSearchCloseClick}
        searchBarBackgroundColor={searchBarBackgroundColor}
        hideSearchToggle={hideSearchToggle}
        styles={headerSearchOverride}
        switchToWhiteStyles={switchToWhiteStyles}
        key={`HeaderSearch-${user?.get("id")}`}
        onFocus={handleFocus}
      />
    );
  };

  const renderMenuToggle = () => (
    <HeaderMenuToggle
      onMenuToggleClick={onMenuToggleClick}
      hideSearchToggle={hideSearchToggle}
      onSearchToggleClick={onSearchToggleClick}
      onSearchToggleKeyDown={handleSearchToggleKeyDown}
      key={`HeaderMenuToggle-${user?.get("id")}`}
    />
  );

  const backgroundStyle = useMemo(
    () =>
      headerContentContainerBackgroundColor
        ? { backgroundColor: headerContentContainerBackgroundColor }
        : {},
    [headerContentContainerBackgroundColor]
  );

  const renderNavItems = (items, side) =>
    items.reduce((contents, item, index) => {
      if (!item.minScreenSize || isWindowSizeOrMore(item.minScreenSize)) {
        contents.push(
          <NavMenuItem
            key={item.id || index}
            item={item}
            styles={side === "left" ? leftStyles : overrideStyles}
            labelStyles={switchToWhiteStyles ? navItemWhiteColor : {}}
            switchToWhiteStyles={switchToWhiteStyles}
          />
        );
      }

      return contents;
    }, []);

  const checkItemPermission = (item) =>
    (!item.permission || userHasPermission(user, item.permission)) &&
    (!item.noPermission || !userHasPermission(user, item.noPermission));

  return (
    <div
      className={css(
        styles.container,
        transparent && styles.transparentContainer,
        switchToWhiteStyles && styles.whiteContainer
      )}
      key={`HeaderStandard-${user?.get("id")}`}
    >
      <div
        className={css(styles.headerContentContainer)}
        style={backgroundStyle}
      >
        <div
          className={css(
            styles.headerContent,
            styles.mainHeaderContainer,
            showSearch && styles.headerContentSearchVisible
          )}
        >
          {renderMenuToggle()}
          {renderLogo()}
          <div className={css(styles.mainMenuContainer)}>
            <div className={css(styles.secondaryMenuContainer)}>
              <div className={css(styles.section, styles.sectionLeft)}>
                {renderNavItems(leftItems.filter(checkItemPermission), "left")}
              </div>
              {showSearch && renderSearch()}
              <div className={css(styles.section, styles.sectionRight)}>
                {renderNavItems(
                  rightItems.filter(checkItemPermission),
                  "right"
                )}
              </div>
            </div>
          </div>
          {showUserContainer && renderUser()}
        </div>
      </div>
    </div>
  );
};

HeaderStandard.propTypes = {
  onMenuToggleClick: PropTypes.func,
  showUserContainer: PropTypes.bool,
  transparent: PropTypes.bool,
  searchBarBackgroundColor: PropTypes.string,
  headerContentContainerBackgroundColor: PropTypes.string,
  hideSearchToggle: PropTypes.bool,
  isHomepage: PropTypes.bool,
};

HeaderStandard.defaultProps = {
  onMenuToggleClick: null,
  showUserContainer: false,
  transparent: true,
  searchBarBackgroundColor: null,
  headerContentContainerBackgroundColor: null,
  hideSearchToggle: false,
};

export default HeaderStandard;
