import format from "date-fns/format";

const TODAY = new Date();
export const DEFAULT_DATE = format(
  TODAY.toLocaleDateString("en-US", { timeZone: "America/New_York" }),
  "YYYY-MM-DD"
);
export const DEFAULT_PLATFORM = "apple";
export const DEFAULT_CATEGORY = "Top Podcasts";
export const DEFAULT_COUNTRY = "us";
