import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Dashboard = Loadable(() => import("./Dashboard"));

function DashboardAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const DashboardContainer = (props) => {
  return <Dashboard {...props} fallback={<DashboardAsyncLoading />} />;
};

export default DashboardContainer;
