import { commonModuleGroup } from "modules/groups";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

import OAuthPrompt from "pages/OAuthConnect/OAuthPrompt";
import OAuthRoute from "pages/OAuthConnect/OAuthRoute";

import App from "./App";

const AppContainer = ({ nonce }) => {
  return (
    <Switch>
      <OAuthRoute
        path="/do-auth"
        component={<OAuthPrompt />}
        modules={[...commonModuleGroup]}
      />
      <App nonce={nonce} />
    </Switch>
  );
};

AppContainer.propTypes = {
  nonce: PropTypes.string,
};

AppContainer.defaultProps = {
  nonce: null,
};

export default AppContainer;
