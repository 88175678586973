import qs from "qs";

import { URL_REGEX } from "constants/app";

export function getEntityUrlPrefix(type) {
  const prefixes = {
    tag: "tags",
    category: "categories",
    podcast: "podcasts",
    episode: "episodes",
    userlist: "lists",
    creator: "creators",
  };

  if (!prefixes[type]) {
    throw new Error(`Unknown entity type ${type}`);
  }

  return prefixes[type];
}

export function formatUrl(url) {
  url = url.replace(/^https?:\/\//, "");

  url = url.replace(/^www\./, "");

  url = url.replace(/\/$/, "");

  return url;
}
// find & remove protocol (http, ftp, etc.) and get hostname
export function extractHostname(url) {
  let hostname;
  if (!url || url.length === 0) {
    return url;
  }

  if (url.indexOf("://") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  // find & remove port number
  hostname = hostname.split(":")[0];
  // find & remove "?"
  hostname = hostname.split("?")[0];

  return hostname;
}

export function extractSimpleDomain(url) {
  const domain = extractHostname(url);

  if (!domain || domain.length === 0) {
    return domain;
  }

  const splitArr = domain.split(".");

  const arrLen = splitArr.length;

  const domainArray = [];
  for (let i = 0; i < arrLen; i++) {
    if (splitArr[i] !== "www") {
      domainArray.push(splitArr[i]);
    }
  }

  return domainArray.join(".");
}

export const matchesRouteWithParams = (
  rawCurrentRoute,
  matchRoute,
  exact = false
) => {
  if (/[:]/.test(matchRoute)) {
    const routeSplit = matchRoute.split("/");
    const currentRoute = rawCurrentRoute && rawCurrentRoute.replace(/\/$/, "");
    const pathSplit = currentRoute.split("/");

    if (!exact || routeSplit.length === pathSplit.length) {
      return routeSplit.every(
        (subRoute, index) =>
          /[:]/.test(subRoute) || subRoute === pathSplit[index]
      );
    }
  }

  return false;
};

export const getParams = (currentRoute, matchRoute) => {
  const routeSplit = matchRoute.split("/").slice(1);
  const pathSplit = currentRoute.split("/").slice(1);
  let pathStillContainsCorrectSections = true;

  return routeSplit.reduce((params, paramKey, index) => {
    const isParam = /[:]/.test(paramKey);

    if (!(pathSplit[index] && (isParam || pathSplit[index] === paramKey))) {
      pathStillContainsCorrectSections = false;
    }

    if (pathStillContainsCorrectSections && isParam) {
      const value = pathSplit[index];

      return {
        ...params,
        [paramKey.replace(":", "")]:
          value && value.includes("?") ? value.split("?")[0] : value,
      };
    }

    return params;
  }, {});
};

export const getQueries = (pathname) => {
  const search =
    typeof pathname === "string" ? pathname.split("?")[1] : pathname;

  return parseStringToQuery(search);
};

export const parseStringToQuery = (string) => qs.parse(string);

export const formatQueries = (queries, options = {}) =>
  qs.stringify(queries, options);

export function ensureScheme(url) {
  if (url && !url.match(/^https?:\/\//)) {
    return `https://${url.replace(/^\s*(.*)\s*$/, "$1")}`;
  }
  return url;
}

export const getLastUrlParam = (url = "") => {
  const splitUrl = url
    .split("/")
    // Remove empty strings
    .filter((string) => string);
  const lastParam = splitUrl[splitUrl.length - 1];
  const lastParamWithoutQueries = lastParam.split("?")[0];

  if (lastParamWithoutQueries && lastParamWithoutQueries !== "") {
    return lastParamWithoutQueries;
  }

  return null;
};

export const isUrl = (str = "") => URL_REGEX.test(str);

export const mergeNewQueryParamsWithUrl = (
  redirectUri,
  additionalQueryParams
) => {
  const indexOfQuestionMark = redirectUri.indexOf("?");
  let redirectQuery = indexOfQuestionMark
    ? qs.parse(redirectUri.substr(indexOfQuestionMark + 1))
    : {};
  redirectQuery = { ...redirectQuery, ...additionalQueryParams };

  return `${redirectUri.substr(0, indexOfQuestionMark)}?${qs.stringify(
    redirectQuery
  )}`;
};
