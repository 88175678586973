import { faMicrophoneAlt } from "@fortawesome/free-solid-svg-icons/faMicrophoneAlt";
import { faNetworkWired } from "@fortawesome/free-solid-svg-icons/faNetworkWired";
import { faTag } from "@fortawesome/free-solid-svg-icons/faTag";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons/faUserCircle";
import { faAnalytics } from "@fortawesome/pro-solid-svg-icons/faAnalytics";

import NavDropdownMenu, {
  defaultMenuStyles,
} from "./NavMenuDropdownItem/DropdownNavMenu";

import userHasInternalPermission from "hooks/useUserHasInternalPermission";

import faEpisode from "styles/icons/faEpisode";
import faList from "styles/icons/faList";
import faPodchaser25 from "styles/icons/faPodchaser25";
import ScreenSizes from "styles/ScreenSizes";

const menuStyles = {
  ...defaultMenuStyles,
  menuContainer: {
    ...defaultMenuStyles.menuContainer,
    maxWidth: "100%",

    [ScreenSizes.lgAndAbove]: {
      width: "24.375rem",
    },
  },
};

export const NAV_LINKS = [
  {
    id: "podcasts",
    dataId: "podcasts",
    label: "Podcasts",
    description: "Discover new podcasts to follow",
    url: "/podcasts",
    icon: faMicrophoneAlt,
  },
  {
    id: "episodes",
    dataId: "episodes",
    label: "Episodes",
    description: "Listen to episodes from any podcast",
    url: "/episodes",
    icon: faEpisode,
  },
  {
    id: "categories",
    dataId: "categories",
    label: "Categories",
    description: "Browse podcasts and episodes by topic",
    url: "/categories",
    icon: faTag,
  },
  {
    id: "lists",
    dataId: "lists",
    label: "Lists",
    description: "Curated playlists from the community",
    url: "/lists",
    icon: faList,
  },
  {
    id: "charts",
    dataId: "charts",
    label: "Charts",
    description: "Browse Spotify and Apple Podcasts Charts",
    url: "/charts",
    icon: faAnalytics,
    badge: { text: "New" },
  },
  {
    id: "networks",
    dataId: "networks",
    label: "Networks",
    description: "Explore network catalogues ",
    url: "/networks",
    icon: faNetworkWired,
    badge: { text: "New" },
  },
  {
    id: "creators",
    dataId: "creators",
    label: "Credits",
    description: "Hosts, guests, and crew",
    url: "/creators",
    icon: faUserCircle,
  },
  {
    id: "podchaser25",
    dataId: "podchaser25",
    label: "Podchaser 25",
    description: "View top 25 articles",
    url: "/articles/podchaser-25",
    icon: faPodchaser25,
  },
];
const BrowseNavMenu = (props) => {
  const userIsInternal = userHasInternalPermission();
  const filteredNavLinks = userIsInternal
    ? NAV_LINKS
    : NAV_LINKS.filter((item) => item.id !== "networks");

  return (
    <NavDropdownMenu
      {...props}
      menuItems={filteredNavLinks}
      menuStyles={menuStyles}
      asLink
    />
  );
};

export default BrowseNavMenu;
