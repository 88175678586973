import PropTypes from "prop-types";
import { createContext, useMemo, useContext } from "react";

import RequestContext from "pages/RequestContext";

import { getCookie, setCookie } from "utils/cookies";

const AbTestingContext = createContext();

export const AB_TESTING_COOKIE = "AB_TESTING";
export const AB_TESTING_COOKIE_CONNECTOR = "&";

export const AB_TESTING_VERSIONS = {
  CONTROL: "CONTROL",
  TEST: "TEST",
};

export const AB_TESTING_EXPERIMENTS = {
  HOMEPAGE_SEARCH: "HOMEPAGE_SEARCH",
};

export function useAbTestingContext() {
  const context = useContext(AbTestingContext);

  return context;
}

function getAbTestingVersion(cookie, abTestingName) {
  const cookieLowerCase = cookie && cookie?.toLowerCase();
  if (
    cookieLowerCase &&
    cookieLowerCase.includes(
      `${abTestingName}=${AB_TESTING_VERSIONS.CONTROL}`.toLowerCase()
    )
  ) {
    return AB_TESTING_VERSIONS.CONTROL;
  } else if (
    cookieLowerCase &&
    cookieLowerCase.includes(
      `${abTestingName}=${AB_TESTING_VERSIONS.TEST}`.toLowerCase()
    )
  ) {
    return AB_TESTING_VERSIONS.TEST;
  }

  return AB_TESTING_VERSIONS.CONTROL;
}

export function setCookieIfNoExists(abTestingName) {
  const cookie = getCookie(AB_TESTING_COOKIE);

  if (cookie && cookie.includes(abTestingName)) {
    return;
  }

  const oneYearExpiries = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );

  const newCookie = cookie
    ? `${cookie}${AB_TESTING_COOKIE_CONNECTOR}${abTestingName}=${AB_TESTING_VERSIONS.TEST}`
    : `${abTestingName}=${AB_TESTING_VERSIONS.TEST}`;

  setCookie(AB_TESTING_COOKIE, newCookie, {
    expires: oneYearExpiries,
  });
}

const AbTestingProvider = (props) => {
  const { children } = props;

  const requestContext = useContext(RequestContext);

  const serverCookie =
    requestContext.server && requestContext.cookies.get("AB_TESTING");
  const clientCookie = getCookie(AB_TESTING_COOKIE);

  const homePageSearchAbTesting = getAbTestingVersion(
    requestContext.server ? serverCookie : clientCookie,
    AB_TESTING_EXPERIMENTS.HOMEPAGE_SEARCH
  );

  const contextState = useMemo(
    () => ({
      isNewHomePageSearch: homePageSearchAbTesting === AB_TESTING_VERSIONS.TEST,
      isOldHomePageSearch:
        homePageSearchAbTesting === AB_TESTING_VERSIONS.CONTROL,
    }),
    [homePageSearchAbTesting]
  );

  return (
    <AbTestingContext.Provider value={contextState}>
      {children}
    </AbTestingContext.Provider>
  );
};

AbTestingProvider.propTypes = {
  children: PropTypes.node,
};

AbTestingProvider.defaultProps = {
  children: null,
};

export default AbTestingProvider;
