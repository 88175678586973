import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment } from "react";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    minHeight: 300,
    background: "none",
    color: colours.greyishBlue,
    fontSize: "1.7em",
  },
  label: {
    margin: "0 0 0 0.6em",
    padding: 0,
    ...gStyles.fontRegular,
    color: colours.greyishBlue,
    fontSize: "1rem",
  },
  noOverlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flex: 1,
    padding: "1rem",
    background: "none",
    color: colours.greyishBlue,
    fontSize: "1.7rem",
  },
  noPadding: {
    padding: 0,
  },
  pageHeader: {
    width: "100%",
    ...gStyles.gradientBackground,
    [ScreenSizes.lgAndAbove]: {
      height: "7rem",
    },
  },
  icon: {
    display: "block",
    maxWidth: "1.7em",
  },
  spanIcon: {
    maxWidth: ".875em",
  },
  span: {
    fontSize: ".875rem",
  },
  startAlign: {
    alignItems: "start",
    paddingTop: 150,
  },
};

const LoadingOverlay = (props) => {
  const {
    noOverlay,
    noPadding,
    withPageHeader,
    noSpinner,
    height,
    span,
    className,
    topAlign,
  } = props;

  const { styles } = useStyles(
    [
      baseStyles,
      {
        height: {
          height,
          maxHeight: height,
          minHeight: 0,
        },
      },
    ],
    props
  );

  const LoadingComp = span ? "span" : "div";

  const loadingContent = (
    <LoadingComp
      id="loading-overlay"
      data-id="loading-overlay"
      data-testid="loading-overlay"
      key="loading-overlay"
      className={`${css(
        noOverlay ? styles.noOverlay : styles.overlay,
        noPadding && styles.noPadding,
        span && styles.span,
        height && styles.height,
        topAlign && styles.startAlign
      )} ${className}`}
    >
      {!noSpinner && (
        <FontAwesomeIcon
          className={css(styles.icon, span && styles.spanIcon)}
          icon={faCircleNotch}
          spin
          title="spinner"
          dataid="spinner"
        />
      )}
    </LoadingComp>
  );

  if (withPageHeader) {
    return (
      <Fragment>
        {withPageHeader && <div className={css(styles.pageHeader)} />}
        {loadingContent}
      </Fragment>
    );
  }

  return loadingContent;
};

LoadingOverlay.propTypes = {
  noOverlay: PropTypes.bool,
  noPadding: PropTypes.bool,
  withPageHeader: PropTypes.bool,
  noSpinner: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  span: PropTypes.bool,
  topAlign: PropTypes.bool,
  className: PropTypes.string,
};

LoadingOverlay.defaultProps = {
  noOverlay: false,
  noPadding: false,
  withPageHeader: false,
  noSpinner: false,
  height: null,
  span: false,
  topAlign: false,
  className: "",
};

export default memo(LoadingOverlay);
