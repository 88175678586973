import { memo, useCallback, useEffect, useRef } from "react";

import authActions from "actions/auth";
import { googleClientID } from "constants/base";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useLoadScript from "hooks/useLoadScript";
import { useIsAuthUser, useLoggedIn } from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";

const GoogleOneTap = () => {
  const hasBeenLoggedInRef = useRef(false);
  const hasAuth = useIsAuthUser();
  const isLoggedIn = useLoggedIn();
  const googleLibrary = window && window.google;
  const googleExists = !!googleLibrary;

  const returnedFromSocialAuth = useReduxState(
    (state) => state.auth.get("returnedFromSocialAuth"),
    []
  );

  const { googleOneTapLogin } = useActionCreators({
    googleOneTapLogin: authActions.googleOneTapLogin,
  });

  useEffect(() => {
    if (isLoggedIn) {
      hasBeenLoggedInRef.current = true;
    }
  }, [isLoggedIn]);

  const loadScript = useCallback(() => {
    googleLibrary.accounts.id.initialize({
      client_id: googleClientID,
      callback: (response) => googleOneTapLogin(response),
    });
    googleLibrary.accounts.id.prompt((notification) => {
      if (notification.isDisplayed()) {
        sendGAEvent({
          action: "googleOneTapLoginDisplayed",
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldLoadScript =
    !hasAuth &&
    !hasBeenLoggedInRef.current &&
    !returnedFromSocialAuth &&
    googleExists;

  useLoadScript("google-onetap", loadScript, {
    disable: !shouldLoadScript,
    timeout: 4000,
  });

  if (!hasAuth) {
    return (
      <script async src="https://accounts.google.com/gsi/client" defer></script>
    );
  }

  return null;
};

export default memo(GoogleOneTap);
