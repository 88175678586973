import { css } from "aphrodite";
import format from "date-fns/format";
import subDays from "date-fns/sub_days";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import DateDropdownSelect from "components/Common/DateDropdownSelect";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  inputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  toInputContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  inputInner: {
    flexDirection: "column",
    display: "flex",

    [ScreenSizes.mdAndAbove]: {
      flexDirection: "row",
    },
  },
  label: {
    ...gStyles.fontSemiBold,
    display: "flex",
    flex: 1,
    fontSize: ".7rem",
    alignItems: "center",
    paddingBottom: "0.4rem",
    color: colours.oldSecondary,
  },
  currentDateLabel: {
    paddingBottom: 0,
    marginLeft: ".5rem",
  },
  currentDateLabelActive: {
    color: "var(--color-selected-d5)",
  },
  currentDateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "1rem",
    cursor: "pointer",

    [ScreenSizes.mdAndAbove]: {
      marginLeft: "1.5rem",
      marginTop: 0,
    },
  },
  applyBtn: {
    cursor: "pointer",
    color: colours.primary,
    background: "transparent",
    border: "none",
    marginTop: "1.5rem",
    padding: 0,
    justifyContent: "flex-end",
  },
  currentWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
};

const overrideStyles = {
  inputField: {
    borderRadius: 25,
    background: colours.selectedLightBlue,
    color: colours.selectedDarkBlue,
    border: `0.5px solid ${colours.selectedSemiLightBlue}`,
    ":hover": {
      background: colours.lightGreyishBlue,
    },
    ":focus": {
      background: colours.lightGreyishBlue,
    },
  },
};

const DATE_FORMAT = "YYYY-MM-DD";
const nowDate = new Date();
const nowFormatted = format(nowDate, DATE_FORMAT);

const getMaxDate = () => {
  return new Date();
};
const getMinDate = (passedMinDate) => {
  const newMin = subDays(new Date(), 90);

  return newMin;
};

const SelectDateRange = (props) => {
  const {
    onChange,
    maxDate: passedMaxDate,
    minDate: passedMinDate,
    value,
    hasPro,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const handleChange = useCallback(
    (day) => {
      onChange(format(day, DATE_FORMAT));
    },
    [onChange]
  );

  const handleDropdownChange = useCallback(
    (date) => handleChange(date),
    [handleChange]
  );

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.inputContainer)}>
        <div className={css(styles.inputInner)}>
          <DateDropdownSelect
            value={value}
            minDate={passedMinDate || getMinDate()}
            maxDate={passedMaxDate || getMaxDate()}
            defaultDate={nowFormatted}
            onChange={handleDropdownChange}
            disabled={!hasPro}
            name={"chartDate"}
            styles={overrideStyles}
          />
        </div>
      </div>
    </div>
  );
};

SelectDateRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  dateInputStyles: PropTypes.object,
  hideTo: PropTypes.bool,
  current: PropTypes.bool,
  hideCurrent: PropTypes.bool,
  onCurrentChange: PropTypes.func,
  selected: PropTypes.shape({
    from: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }),
};

SelectDateRange.defaultProps = {
  dateInputStyles: null,
  hideTo: false,
  current: false,
  hideCurrent: false,
  onCurrentChange: null,
  seleted: null,
};

export default memo(SelectDateRange);
