import LayoutSizes from "./LayoutSizes";

import generateTransition from "utils/generateTransition";

export const secondaryVisibleStyles = {
  sectionsWrapper: {
    [LayoutSizes.hasTabletMenu]: {
      transform: "translateX(40%)", // TODO IMPORTANT: GET THIS WORKING AGAIN
    },

    [LayoutSizes.hasMobileMenu]: {
      transform: "translateX(80%)", // TODO IMPORTANT: GET THIS WORKING AGAIN
    },
  },
};

export const tertiaryVisibleStyles = {
  wrapper: {
    overflowY: "visible",
    overflowX: "hidden",
  },
  sectionsWrapper: {
    [LayoutSizes.hasTabletMenu]: {
      transform: "translateX(40%)",
    },

    [LayoutSizes.hasMobileMenu]: {
      transform: "translateX(80%)",
    },
  },
};

export const layoutStyles = {
  wrapper: {
    height: "100%",
  },
  wrapper__body: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: 780,
    // minHeight: '100vh', // This is what hides the footer in screenshots and Fetch As Google
    flex: "1 1 100%",
    overflow: "hidden",
  },
  wrapper__content: {
    display: "flex",
    flex: "none",
    flexDirection: "column",
    minHeight: 0,
    minWidth: 0,
  },
  sectionsWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    overflowY: "hidden",
    overflowX: "hidden",
    minHeight: 0,
    minWidth: 0,
    flex: "none",

    [LayoutSizes.hasMobileOrTabletMenu]: {
      transition: generateTransition({
        targets: ["transform"],
        speed: "150ms",
      }),
      overflow: "visible",
    },
  },
  sidebarContainer: {
    width: "0",
    height: "100%",
    position: "absolute",
    zIndex: 15,
    [LayoutSizes.hasTabletMenu]: {
      width: "40%",
      right: "100%",
    },

    [LayoutSizes.hasMobileMenu]: {
      width: "80%",
      right: "100%",
    },
  },
  anchor: {
    color: "#882",
    textDecoration: "underline",
  },
};
