import PropTypes from "prop-types";
import pick from "ramda/src/pick";
import { memo, useCallback, forwardRef } from "react";

const BasicSelect = forwardRef((props, ref) => {
  const {
    multiple,
    onChange,
    options,
    selectClassName,
    optionClassName,
    dataId,
    dataTestId,
  } = props;
  const handleChange = useCallback(
    (e) => {
      if (multiple) {
        onChange(
          [...e.target.options].filter((o) => o.selected).map((o) => o.value)
        );
      } else {
        onChange(e.target.value);
      }
    },
    [onChange, multiple]
  );

  return (
    <select
      data-testid={dataTestId}
      data-id={dataId}
      id={dataId}
      onChange={handleChange}
      className={selectClassName}
      {...pick(
        [
          "style",
          "disabled",
          "name",
          "onFocus",
          "onBlur",
          "onMouseEnter",
          "onMouseLeave",
          "value",
          "multiple",
        ],
        props
      )}
      ref={ref}
    >
      {options.map((option, i) => {
        const key = `basicSelectOption${i}`;

        return (
          <option
            data-id={`${dataId}-option-${option.value}`}
            key={key}
            className={optionClassName}
            value={option.value}
            hidden={option.hidden}
          >
            {option.label}
          </option>
        );
      })}
    </select>
  );
});

BasicSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  selectClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  style: PropTypes.object,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  dataId: PropTypes.string,
};

BasicSelect.defaultProps = {
  value: null,
  selectClassName: null,
  optionClassName: null,
  style: null,
  multiple: false,
};

export default memo(BasicSelect);
