import { commonModuleGroup, podcastModuleGroup } from "modules/groups";
import { Switch } from "react-router-dom";

import TopLevelRoute from "pages/Common/TopLevelRoute";

import ChartsHubPage from "../../ChartsHub/async";
import Charts from "./async";

function ChartRoutes() {
  return (
    <Switch>
      <TopLevelRoute
        path="/charts"
        exact
        transparent={false}
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <ChartsHubPage />
      </TopLevelRoute>
      <TopLevelRoute
        path="/charts/:platformId/:countryId?/:categoryId?"
        exact
        transparent={false}
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <Charts />
      </TopLevelRoute>
    </Switch>
  );
}

export default ChartRoutes;
