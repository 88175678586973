import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

const loadingStyles = {
  height: {
    height: "365rem",
    maxHeight: "365rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "280rem",
      maxHeight: "280rem",
    },
  },
};

const PodcastAboutViewContainer = Loadable(() =>
  import("./PodcastAboutViewContainer")
);

function PodcastAboutViewContainerAsyncLoading() {
  return (
    <LoadingOverlay withPageHeader height={"280rem"} styles={loadingStyles} />
  );
}

const PodcastAboutViewContainerContainer = (props) => {
  return (
    <PodcastAboutViewContainer
      {...props}
      fallback={<PodcastAboutViewContainerAsyncLoading />}
    />
  );
};

export default PodcastAboutViewContainerContainer;
